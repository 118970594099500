
@font-face {
  font-family: "Everett";
  src: url("/assets/fonts/Everett-Regular-web?#iefix");
  src: url("/assets/fonts/Everett-Regular-web?#iefix") format("eot"),
    url("/assets/fonts/Everett-Regular-web.woff2") format("woff2"),
    url("/assets/fonts/Everett-Regular-web.woff") format("woff"),
    url("/assets/fonts/Everett-Regular-web.ttf") format("truetype"),
    url("/assets/fonts/Everett-Regular-web.svg#Everett-Regular-web")
      format("svg");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Everett";
  src: url("/assets/fonts/Everett-Bold-web?#iefix");
  src: url("/assets/fonts/Everett-Bold-web?#iefix") format("eot"),
    url("/assets/fonts/Everett-Bold-web.woff2") format("woff2"),
    url("/assets/fonts/Everett-Bold-web.woff") format("woff"),
    url("/assets/fonts/Everett-Bold-web.ttf") format("truetype"),
    url("/assets/fonts/Everett-Bold-web.svg#Everett-Bold-web")
      format("svg");
      font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Everett";
  src: url("/assets/fonts/Everett-Light-web?#iefix");
  src: url("/assets/fonts/Everett-Light-web?#iefix") format("eot"),
    url("/assets/fonts/Everett-Light-web.woff2") format("woff2"),
    url("/assets/fonts/Everett-Light-web.woff") format("woff"),
    url("/assets/fonts/Everett-Light-web.ttf") format("truetype"),
    url("/assets/fonts/Everett-Light-web.svg#Everett-Light-web")
      format("svg");
      font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Everett";
  src: url("/assets/fonts/Everett-Medium-web?#iefix");
  src: url("/assets/fonts/Everett-Medium-web?#iefix") format("eot"),
    url("/assets/fonts/Everett-Medium-web.woff2") format("woff2"),
    url("/assets/fonts/Everett-Medium-web.woff") format("woff"),
    url("/assets/fonts/Everett-Medium-web.ttf") format("truetype"),
    url("/assets/fonts/Everett-Medium-web.svg#Everett-Medium-web")
      format("svg");
      font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Everett";
  src: url("/assets/fonts/Everett-Thin-web?#iefix");
  src: url("/assets/fonts/Everett-Thin-web?#iefix") format("eot"),
    url("/assets/fonts/Everett-Thin-web.woff2") format("woff2"),
    url("/assets/fonts/Everett-Thin-web.woff") format("woff"),
    url("/assets/fonts/Everett-Thin-web.ttf") format("truetype"),
    url("/assets/fonts/Everett-Thin-web.svg#Everett-Thin-web")
      format("svg");
      font-weight: 100;
  font-display: swap;
}


@font-face {
  font-family: "Anko";
  src: url("/assets/fonts/Anko-RegularItalic.eot?#iefix");
  src: url("/assets/fonts/Anko-RegularItalic.eot?#iefix") format("eot"),
    url("/assets/fonts/Anko-RegularItalic.woff") format("woff"),
    url("/assets/fonts/Anko-RegularItalic.ttf") format("truetype"),
    url("/assets/fonts/Anko-RegularItalic.svg#Anko-RegularItalic")
      format("svg");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Anko";
  src: url("/assets/fonts/Anko-Regular.woff") format("woff"),
    url("/assets/fonts/Anko-Regular.otf") format("truetype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'IBMPlexMono-Light';
  src: url('/assets/fonts/IBMPlexMono-Light.eot');
  src: url('/assets/fonts/IBMPlexMono-Light.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/IBMPlexMono-Light.otf') format('otf'),
       url('/assets/fonts/IBMPlexMono-Light.svg#IBMPlexMono-Light') format('svg'),
       url('/assets/fonts/IBMPlexMono-Light.ttf') format('truetype'),
       url('/assets/fonts/IBMPlexMono-Light.woff') format('woff'),
       url('/assets/fonts/IBMPlexMono-Light.woff2') format('woff2');
}
