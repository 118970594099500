.lang-selected-he {
    direction: rtl;

    .country {
        .icon-country {
            margin-left: 10px !important;
        }
    }

    .selector-bottom-row {
        .selector-bottom-column {
            button {
                margin-right: 40px !important;
                margin-left: 0px !important;
            }
        }

        .mxe {
            &__model-basic-infos {
                padding-left: 29px !important;
            }

            &__model-basic-info-row {
                span {
                    text-align: right !important;
                }
            }

            &__model-dimensions-info-item {
                span {
                    text-align: right !important;
                    margin-left: 20px !important;
                }

                b {
                    width: 150px !important;
                    text-align: right !important;

                    @media (max-width: $small-ipad-media) {
                        margin-left: 20px !important;
                    }
                }
            }

            &__button-select-family {
                text-align: right !important;
                background-position: calc(100% - 236px) center !important;
                background-image: url("./assets/icons/mxe_ico-arrow-left-black.svg") !important;
            }
        }
    }

    .mxe__tab-elements {
        right: 395px !important;
    }

    .maserati__car-configurator-video-wrapper {
        direction: ltr !important;
        // width: calc(372vw - $sidebar-width) !important;

        @media (max-width: $small-ipad-media) {
            //width: calc(372vw - $sidebar-mobile-width) !important;
            direction: ltr !important;
        }

        @media (min-width: $tv-media) {
            //width: calc(372vw - $sidebar-tv-width) !important;
            direction: ltr !important;
        }

        &.cast-is-active {
            width: 660px;
            height: 370px;
            position: absolute;
            top: 50%;
            left: $standard-padding;
            transform: translate(0, -50%);
            border: 3px solid $accent;

            @media (max-width: $small-ipad-media) {
                margin-top: -70px;
                width: 520px;
                height: 290px;
                left: 30px;
            }

            transition: none;
        }
    }

    .mxe__car-upper-menu {
        direction: ltr !important;
    }


    /* .mxe__car-upper-menu{
        .mxe__search-toggle{
            left: 0 !important;
            right: 495px !important;
            .mxe-toggle-button{
                left: 0 !important;
            }
            .mxe-search-panel{
                left: 75px !important;
                right: -495px !important;
            }
        }
    }*/

    //Home-menu
    .mxe {
        &__home-menu-wrapper {
            .mxe__menu-items-container {
                .mxe__custom--accordion {
                    .icon-menu {
                        margin-left: 30px !important;
                        margin-right: 60px !important;
                    }

                    .mxe__dropdown-arrow {
                        left: 0 !important;
                        margin-left: 60px !important;
                        right: 740px !important;
                        margin-right: 0 !important;
                    }
                }

                .btn {
                    .icon-lang {
                        margin-right: 10px !important;
                    }

                    .icon-menu {
                        margin-left: 30px !important;
                        margin-right: 60px !important;
                    }
                }
            }
        }
    }

    .lang {
        padding-right: 129px !important;
        padding-left: 0 !important;
    }

    //Load a configuration
    .igroup {
        label {
            right: 0 !important;
        }
    }

    .mxe__config-loader--container {
        .bar {
            span {
                margin-left: 10px !important;
                margin-right: 0 !important;
            }
        }
    }

    .switch-button {
        .switch {
            margin-right: 10px !important;
        }
    }

    .swiper-pagination {
        direction: ltr !important;
    }

    .swiper {
        direction: ltr !important;

        .mxe__bottom-car-selector-wrapper {
            direction: rtl !important;
        }
    }

    //Car Model Data
    .car-model-data-wrapper {
        left: 630px !important;
        right: 0 !important;

        .maserati__car-configurator-upper-panel {
            right: 685px !important;

            .basic-infos {
                .car-name {
                    text-align: right !important;
                }
            }
        }
    }

    //Exterior
    .btn-col-selection {
        margin: 0px 0px 10px 10px !important;
    }

    .mxe__exterior--accordion {
        .mxe__exterior-color-config-items {
            .squareContainer {
                margin-left: -25px !important;
                margin-right: 0 !important;
            }
        }

        .mxe__toggle-arrow {
            right: 45px !important;
        }

        .mxe__btn-play-video {
            right: 0px !important;
        }
    }

    //Interior
    .mxe__interior--accordion {
        .mxe__toggle-arrow {
            right: 45px !important;
        }

        .mxe__btn-play-video {
            right: 0px !important;
        }
    }

    //Packages
    .mxe__packages-config-panel {
        .pkg-accordion {
            .pkg-content-list {
                padding-right: 50px !important;
            }

            .pkg-accordion-heading {
                .absolute-pkg-infos {
                    right: 20px !important;
                }
            }
        }
    }

    .btn-action-package {
        left: 20px !important;
        right: auto !important;
    }



    .accordion-toggle {
        left: 20px !important;
        right: auto !important;
    }


    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        right: 20px !important;
    }


    //Optional
    .mxe__optional-config-wrapper {
        .optional {
            .mxe__optional-image {
                margin-left: 30px !important;
                margin-right: 0 !important;
            }
        }
    }

    .selected-options-infos {
        text-align: right !important;
    }


    //Accessories
    .mxe__optional-config-panel {
        .optional {
            .mxe__optional-image {
                margin-right: 0 !important;
                margin-left: 30px !important;
            }
        }
    }

    .mxe__btn-remove {
        margin: 20px 0px 20px 20px !important;
    }

    .mxe__btn-add {
        margin: 20px 0px 20px 20px !important;
    }

    //Summary
    .mxe__summary-configuration--wrapper {
        direction: ltr !important;
    }


    //Presenter Screen Modal
    .mxe__presenter-screen-modal {
        .mxe__presenter-screen-heading {
            h1 {
                padding-right: 40px !important;
                padding-left: 0 !important;
            }
        }

        .mxe__presenter-screen-camera {
            .disclaimer-camera {
                img {
                    margin-left: 20px !important;
                    margin-right: 0 !important;
                }
            }
        }

        button.mxe__close-action,
        a.mxe__close-action {
            background-image: url("/assets/icons/mxe-icon_close.svg") !important;
            background-size: 64px !important;
            width: 64px !important;
            height: 64px !important;
            left: 30px !important;
            right: auto !important;
            top: 30px !important;
        }
    }

    //Error modal

    .mxe__afk-modal-content {
        text-align: right !important;
    }

    //Countries
    .mxe__lang-item,
    .country-active {
        &:before {
            margin-left: 20px !important;
            margin-right: 0px !important;
        }
    }

    .mxe__language-selector-modal {

        .modal-content,
        .modal-content-confirmation {
            h1 {
                text-align: right !important;

            }
        }
    }


    //Home Menu
    .mxe__home-menu-wrapper {
        .mxe__menu-items-container {
            .btn {
                .icon-trident-menu {
                    margin-left: 29px !important;
                    margin-right: 22px !important;
                }
            }
        }

        .switch-button-home {
            left: 60px !important;
            right: auto !important;

            .switch {
                margin-left: 15px !important;
                margin-right: 0 !important;
            }
        }

        .btn-x {
            right: 60px !important;
        }
    }

    .switch-button {
        left: 60px !important;
        right: auto !important;
    }

    .sidebar_mode {
        .switch-button {
            left: 35px !important;
            right: auto !important;
        }

        .burger-menu {
            right: 585px !important;
            left: auto !important;
        }
    }

    .logo-maserati {
        right: 48px !important;
    }

    .mxe__fixed-top-logo {
        right: 48px !important;
        left: auto !important;
    }

    .car-model-data-wrapper {
        .logo-maserati {
            right: 600px !important;
            left: auto !important;
        }
    }

    .burger-menu {
        right: 25px !important;
        left: auto !important
    }

    button.mxe__close-action,
    a.mxe__close-action {
        right: 60px !important;
        left: auto !important;
        background: url("/assets/icons/mxe_ico-arrow-right-white.svg") 100% 50% no-repeat !important;
        text-align: right !important;
        padding-right: 35px !important;
        padding-left: 0 !important;
        margin-right: 10px !important;
        margin-left: 0 !important;
    }

    .switch-button.screenCastActive {
        .slider:before {
            transform: translateX(1px) !important;
        }
    }


    //Summary - if rtl in summary will be required
    /*.mxe__summary-configuration--summary-sidebar--absolute-actions{
    direction:ltr !important;
}
    .btn-send-pdf {
        text-align: right !important;
    }
    .btn-restart {
        background-position: 95% center !important;
        padding-right: 60px !important;
        padding-left: 15px !important;
    }
    
        .btn-arrow-black {
            background-position: calc(20% - 20px) center;
            padding-left: 40px !important;
            padding-right: 20px !important;
        }
        

        .mxe__summary-configuration--car-info {
            img {
                margin-right: 10px !important;
                margin-left: 0 !important;
            }
        } */

    /*  .mxe__summary-configuration {
        &--car-info {
            position: fixed !important;

            .mxe__summary {
                &--configuration {
                    &--car-price {
                        &-label {
                            text-align: right !important;
                        }

                        &-value {
                            text-align: right !important;
                        }
                    }

                    &--similar-cars {
                        h5 {
                            margin: 0 0 15px 0;
                            text-align: right !important;
                        }

                        .btn-similar-models {
                            text-align: right !important;
                        }
                    }
                }
            }
        }

        &--summary-sidebar {
            .mxe__summary-configuration--summary {
                &--title {
                    text-align: right !important;
                }

                &--main-choices {

                    .mxe__summary-configuration--summary--exterior-choice,
                    .mxe__summary-configuration--summary--interior-choice {
                        text-align: right !important;

                        img {
                            margin-left: 13px !important;
                            margin-right: 0px !important;
                        }
                    }
                }

                &--spec-item {
                    margin-left: 90px !important;
                    margin-right: 0px !important;

                    .label {
                        text-align: right !important;
                    }

                    .data {
                        text-align: right !important;

                        .data-label:not(:first-child) {
                            margin-left: 10px !important;
                            margin-right: 0px !important;
                        }
                    }

                    .total {
                        text-align: right !important;

                        .data-label:not(:first-child) {
                            margin-left: 10px !important;
                            margin-right: 0px !important;
                        }
                    }
                }
            }
        }
    } */

    /* .mxe__custom--accordion-content {
        .exterior-data-config-item {

            .choice-price {
                padding-left: 20px;
            }

            .item-choice {
                img {
                    margin-left: 10px !important;
                    margin-right: 0px !important;
                }
            }
        }
    }*/
}