@import "variables.scss";

.async-privacy {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 83%;
  font-family: $font-primary;
  box-sizing: border-box;

  .question {
    font-size: 26px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;

    &.privacy-common-text__title {
      margin-left: 0 !important;
    }
  }

  .privacy {
    margin-left: 0 !important;
    margin-top: 15px;

    .privacy-common-text__sub-title {
      width: 100%;
      text-align: justify;
      margin-bottom: 15px;
      margin-top: 0;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 1.5;

      .tos-trigger {
        text-decoration: underline;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 16px;
        text-align: justify;

      }

    }
  }

  .privacy-direct-marketing {
    margin-left: 0 !important;
    margin-right: 0 !important;
    flex-wrap: wrap;

    .intro-text.privacy {
      margin-top: 15px;
      margin-left: 0 !important;
    }

    .required.not {
      display: none;
    }

    .chkGroup {
      width: calc(100% - 26px);
      vertical-align: top;
      margin-top: 6px;
      display: inline-block;


    }

    #privacyChk,
    #contactChk {
      width: calc(100% - 26px);
      vertical-align: top;
      text-align: left;
      margin-top: 1px;
      display: flex;
      flex-flow: column wrap;


      .checkBoxDescription {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 1.5;
        color: #333;
        position: relative;
        margin-top: -30px;

        a {
          color: #333;
        }

        span {
          .channel-trigger {
            text-decoration: underline;
            cursor: pointer;
          }
        }


      }

      #privacy_direct_marketing--yes {
        visibility: hidden;
        display: none;

      }


      input[type="checkbox"]+label::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        border: 1px solid $black;
        box-sizing: border-box;
        // margin-right: $divider_1;

      }

      input[type="checkbox"]+label+label.checkBoxDescription {
        margin-left: 35px;
        max-width: calc(100% - 55px);
      }

      input[type="checkbox"].error+label::before {
        border: 1px solid red;
      }

      input[type="checkbox"]:checked+label::after {
        content: '\2713';
        position: absolute;
        left: 2px;
        top: -3px;
        width: 10px;
        height: 10px;
        font-size: 16px;
        font-weight: 900;
        text-align: center;

      }


    }

    #privacyChk {
      margin-top: 50px;
    }


  }

  .privacy-direct-marketing--channels {
    display: none;
    padding: 0px 35px;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .inlineblock {
      display: inline-block;
      margin-right: 35px;

      input[type="checkbox"] {
        visibility: hidden;
        display: none;
      }


      input[type="checkbox"]+label::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        border: 1px solid $black;
        box-sizing: border-box;

      }

      input[type="checkbox"]+label+label.checkBoxDescription {
        margin-left: 35px;
        max-width: calc(100% - 55px);
      }

      input[type="checkbox"].error+label::before {
        border: 1px solid red;
      }

      input[type="checkbox"]:checked+label::after {
        content: '\2713';
        position: absolute;
        left: 2px;
        top: -1;
        width: 10px;
        height: 10px;
        font-size: 16px;
        font-weight: 900;
        text-align: center;


      }


      .required.not {
        display: none;
      }

      input {
        width: 100%;
        color: #333;
        font-size: 1pc;
        border-bottom: 1px solid #333;
        background-color: transparent;
        -webkit-box-shadow: 0 0 0;
        box-shadow: 0 0 0;
        padding: 0 0 13px;
        margin-top: 28px;
        border-radius: 0;
      }

      .checkBoxDescription {
        font-size: 15px;
        letter-spacing: 0;
        line-height: 1.5;
        color: #333;
        position: relative;
        margin-top: -30px;

        span {
          margin-left: 4px;
        }

        a {
          color: #333;
        }

      }


    }


  }

  .intro-text {
    margin-top: 0;

    p {
      font-size: 8px;
      width: 100%;
      text-align: justify;
      margin-bottom: 15px;
      letter-spacing: 0;
      line-height: 1.5;
    }

  }

  .privacy-profiling {
    text-align: left;

    .required.not {
      display: none;
    }

    .chkGroup {

      .checkBoxDescription {
        display: flex;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 1.5;
        color: #333;
        position: relative;
        margin-top: -21px;

        a {
          color: #333;
        }


      }

      #privacy_profiling--yes {
        visibility: hidden;
        display: none;

      }

      input[type="checkbox"]+label::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        border: 1px solid $black;
        box-sizing: border-box;
      }

      input[type="checkbox"]+label+label.checkBoxDescription {
        margin-left: 35px;
        max-width: calc(100% - 55px);
      }

      input[type="checkbox"].error+label::before {
        border: 1px solid red;
      }

      input[type="checkbox"]:checked+label::after {
        content: '\2713';
        position: absolute;
        left: 2px;
        top: -2px;
        width: 10px;
        height: 10px;
        font-size: 16px;
        font-weight: 900;
        text-align: center;
      }
    }



  }

}

.privacy-common-text__policy{
  width: 1024px;
  height: 720px;
  position: absolute;
  left: 171px;
  background-color: white;
  overflow-y: scroll;
  &.opened{
    z-index: 999;
    animation: fromBottomZero-animation 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
  }
 
  @media (max-width: $small-ipad-media) {
      width: 860px;
      height: 593px;
      left: 168px;
  }

  .close{
    position: absolute;
    width: 40px;
    height: 40px;
    background-image: url('../src/assets/icons/mxe__ico-exit-menu-black.svg');
    background-size: contain;
    right: 50px;
    top: 50px;
  }

  .description{
    position: relative;
    width: 900px;
    left: 19px;
    margin-top: 100px;

  }    
}

@keyframes fromBottomZero-animation {
  from {
    opacity: 0;
    bottom: 0;
  }
  to {
    opacity: 1;
    bottom: 153px;
  }
}

