// STYLES FOR HOTSPOT BY SIMONA
@import "variables.scss";

body {
  .container-360-wrapper {
    .cloudimage-360-hotspot-popup {
      padding: 0;
      width: 450px;
      height: 250px;
      box-shadow: none;
      background-color: transparent !important;
      .cloudimage-360-modal-wrapper {
        //cambiare sfondo e dare proprietà del popup es. border-bottom ecc..
        background-color: transparent !important;
        width: 450px;
        height: 180px;
        max-width: 450px;
        margin-bottom: 70px;
        border-bottom: 3px solid $accent;
        position: relative;
        &:after{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: "";
          background: rgba(80, 80, 80, 0.1);
          backdrop-filter: blur(10px);
          z-index: 2;
        }

        .cloudimage-360-modal-title {
          color: #fff;
          font-size: 18px;
          font-weight: 400;
          margin-left: 30px;
          margin-right: 30px;
          padding-top: 30px;
          padding-bottom: 30px;
          text-align: left;
          line-height: 18px;
          letter-spacing: -0.457143px;
          text-transform: capitalize;
          z-index: 3;
          position: relative;
        }
        .cloudimage-360-modal-description {
          color: #fff 75%;
          font-size: 14px;
          font-weight: 400;
          margin-left: 30px;
          margin-right: 30px;
          padding-bottom: 30px;
          text-align: left;
          line-height: 23px;
          letter-spacing: -0.4px;
          position: relative;
          z-index: 3;
        }
      }
      .cloudimage-360-popup-arrow,
      .cloudimage-360-popup-arrow::before {
        width: 2px;
        height: 70px;
        background: $accent;
      }
      .cloudimage-360-popup-arrow::before {
        transform: rotate(0deg);
      }
    }
  }
  .cloudimage-360-hotspot-popup[data-popper-placement^="top"] > .cloudimage-360-popup-arrow {
    bottom: -5px;
  }
  .cloudimage-360-hotspot-custom-icon{
    background-image: url('assets/icons/mxe__ico-hotspot-closed.svg');
    background-position: center;
    border: 0 none;
    background-color: transparent;
    &:before{
      opacity: 0;
    }
    &:hover{
      background-image: url('assets/icons/mxe__ico-hotspot-opened.svg');
    }
  }
}

.cloudimage-360-hotspot-popup[data-popper-placement="bottom"] .cloudimage-360-modal-wrapper {
  margin-bottom: 0;
  margin-top: 70px;
  border-top: 3px solid $accent;
  border-bottom: 0 none !important;
}