@import "variables.scss";

.mxe__modal {
    &--save-configuration {
        &--content {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 510px;
            width: 100%;
            min-height: 720px;
            background: $white;
            z-index: 999;
            .mxe__padd{
                padding: $standard-padding;
            }
            @media (max-width: $small-ipad-media) {
                min-height: 658px;
            }
        }
        &--title {
            font-size: 30px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: -0.8571428656578064px;
            text-align: left;
            color: $primary;
            margin-top: 0;
            margin-bottom: 50px;
        }

        &--input-grp {
            display: inline-block;
            padding: 10px;
            margin-bottom: 33px;
            width: 45%;
            &--checkbox-grp {
                margin-bottom: 33px;
            }

            .mxe__modal--save-configuration {
                &--input-label {
                    color: $darkgrey;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 15px;
                    letter-spacing: 0.9999999403953552px;
                    text-align: left;
                    margin-bottom: 15px;
                    display: block;
                }

                &--input-field {
                    background: $white;
                    border-left: 0 none;
                    border-right: 0 none;
                    border-top: 0 none;
                    border-bottom: 2px solid $primary;
                    height: 40px;
                    width: 100%;
                    border-radius: 0;
                    color: $primary;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 26px;
                    letter-spacing: -0.4000000059604645px;
                    text-align: left;
                    &::placeholder {
                        color: $primary;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 26px;
                        letter-spacing: -0.4000000059604645px;
                        text-align: left;
                    }
                    &:focus {
                        outline: none;
                        -webkit-tap-highlight-color: transparent;
                    }
                }
            }
        }
        .modal-backdrop {
            content: "";
            background: rgba(0, 0, 0, 0.8);
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 99;
        }
    }
}
.mxe__modal--save-configuration {
    &.full-modal {
        .mxe__modal--save-configuration--content {
            max-width: 1024px;
            height: 720px;
            @media (max-width: $small-ipad-media) {
                max-width: 860px;
                height: 658px;
            }
        }
    }
    &--acceptation {
        margin-bottom: 80px;
        display: block;
        color: $grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        display: flex;
        align-items: start;
        justify-content: start;
        input[type="checkbox"] {
            margin-right: 10px;
        }
    }
}

.mxe__popup-iframe {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    color: $primary;
    z-index: 999;

    &.full-modal {
        max-width: 1024px;
        height: 720px;

        @media (max-width: $small-ipad-media) {
            max-width: 860px;
            height: 680px;
        }
    }

    &.frame-search-inventory {
    top: 0;
    left: 0;
    right: 0;
    transform: translate(0, 0);
    bottom: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

        iframe{
            position: absolute;
            top:110px;
            width: 100vw;
            height: calc(100vh - 110px);
        }
        .search-inventory-heading {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: $primary;
            height: 110px;
            .inventory-search-title{
                position: fixed;
                top: 40px;
                left: 40px;
                font-weight: 400;
                font-size: 26px;
                line-height: 30px;
                letter-spacing: -0.65px;
                color: $white;
            }
            .trim-selector-close{
                background-image: url('assets/icons/mxe-icon_close.svg');
                top:30px;
                right: 30px;
                width: 64px;
    height: 64px;
    background-size: 64px;
    background-repeat: no-repeat;
            }
        }
    }

    &.frame-more-info {
        top: 0;
        left: 0;
        right: 0;
        transform: translate(0, 0);
        bottom: 0;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        text-transform: capitalize;
            iframe{
                position: absolute;
                top:50%;
                left:50%;
                transform: translate(-50% , -50%);
                max-width:1024px;
                height: 720px;
                @media (max-width: $small-ipad-media) {
                    height: 600px;
                    max-width: 980px;
                    margin-top: 20px;
                }
            }
            .search-inventory-heading {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                background: $primary;
                height: 100vh;
                .inventory-search-title{
                    position: fixed;
                    top: 40px;
                    left: 40px;
                    font-weight: 400;
                    font-size: 26px;
                    line-height: 30px;
                    letter-spacing: -0.65px;
                    color: $white;
                }
                .trim-selector-close{
                    background-image: url('assets/icons/mxe-icon_close.svg');
                    top:30px;
                    right: 30px;
                    width: 64px;
    height: 64px;
    background-size: 64px;
    background-repeat: no-repeat;
                }
            }
        }
}
