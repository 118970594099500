/* You can add global styles to this file, and also import other style files */
@import 'fonts.scss';
@import 'variables.scss';


@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';


@import 'global.scss';
@import 'custom_modals.scss';
@import 'style360component.scss';
@import 'rtl-override.scss';

@import 'async_privacy_style.scss';

body{
  font-family: $font-primary;
  margin: 0;
}

.selected-price-details {
  color: $verydarkgrey;
}
