$primary: #000000;
$accent: #ffc700;
$white: #ffffff;
$white-fifty: rgba(255, 255, 255, 0.5);
$white-thirty: rgba(255, 255, 255, 0.3);
$black: #000000;
$blue: #2381c6;
$grey: #BCBCBC;
$darkgrey: #A1A5A8;
$verydarkgrey:#666666;
$maseratiBlue: #1C3775;
$errorColor: #FB1717;
$summary-conf-grey:#202020;
$size-small: 14px;
$font-primary: 'Everett', 'sans-serif';
$anko: 'Anko', 'serif';
$ibm: 'IBMPlexMono-Light', monospace;

$sidebar-tv-width: 1140px;
$sidebar-width: 570px;
$sidebar-mobile-width: 470px;
$mid-sidebar-width: 285px;
$sidebar-padding: 40px;
$small-ipad-media: 1200px;
$ipadPro-media: 1366px;
$tv-media: 3600px;
$animation-duration: 500ms;

$standard-padding: 63px;

$mobile-padding: 40px;