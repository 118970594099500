//put here overrides and general rules
@import "variables.scss";

@media (min-width: 900px) {
    :root {
        --size-small: 24px;
    }
}

body {
    height: 100vh;
    overflow: hidden;
}
.text-center {
    text-align: center;
}

.btn-arrow {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: center;
    color: $white;
    padding: 16px;
    text-transform: uppercase;
    background-color: transparent;
    border: 0 none;
    background-image: url("./assets/icons/mxe_ico-arrow-right-yellow.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 2px) center;
    padding-right: 23px;
    display:flex;
    height: 24px;
}
.btn {
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: center;
    color: $white;
    padding: 16px;
    text-transform: uppercase;
    background-color: transparent;
    border: 0 none;
    &.btn-bordered {
        border: 1px solid $white;
    }
    &.btn-transparent {
        background-color: transparent;
    }
    &.btn-very-large {
        max-width: 375px;
        width: 100%;
    }
    
    &.btn-close {
        width: 64px;
        height: 64px;
        background-image: url("./assets/icons/mxe-icon_close.svg");
        background-repeat: no-repeat;
        background-size: 64px;
    }
    &.btn-restart {
        background-image: url("./assets/icons/mxe__ico-restart.svg");
        background-repeat: no-repeat;
        background-position: 15px center;
        background-size: 32px;
        padding-left: $standard-padding;
    }
    &.btn-accent {
        background: $accent;
        color: $black;
        border: 1px solid $accent;
    }
    &.btn-arrow-black {
        background-image: url("./assets/icons/mxe-ico_arrow-right.svg");
        background-repeat: no-repeat;
        background-position: calc(100% - 20px) center;
        padding-right: 40px;
    }
    &.btn-fullwidth {
        width: 100%;
    }
    &.btn-dark {
        color: $primary;
        border-color: $primary;
    }
}

// swiper overrides

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet span,
.swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    width: auto;
    height: auto;
    color: $darkgrey;
    font-size: 16px;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-left: 30px;
    margin-right: 30px;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    opacity: 1;
    transition: all 200ms ease;
    border-radius: 0;
    background: transparent;
    text-transform: uppercase;
    padding-bottom: 20px;
    padding-top:10px;
    &:focus,
    &:hover {
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }
    @media (min-width:$tv-media) {
        font-size: 35px;
        line-height: 45px;
        margin-left: 60px;
        margin-right: 60px;
        letter-spacing: 2px;
        padding-bottom: 40px;
    }
    &:only-child {
        display: flex !important;
    }
}
.swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: transparent;
    color: $white;
    font-size: 16px;
    margin-top: 2px;
    border-bottom: 2px solid $white;
    font-weight: 800;
    @media (min-width:$tv-media) {
        font-size: 35px;
        letter-spacing: 2px;
    }
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 35px;
    overflow: scroll;
    width: 100%;
    @media (min-width:$tv-media) {
        padding-top: 70px;
    }
}

.swiper-button-prev {
    width: $standard-padding;
    height: $standard-padding;
    position: absolute;
    z-index: 9;
    top: 48%;
    margin-top: -30px;
    background-color: transparent;

    border: 0 none;
    background-position: center;
    background-repeat: no-repeat;
    left: 0;
    // background-image: url("/assets/icons/mxe__ico-arrow-left.svg");
    background-image: url("/assets/icons/Chevron_Left.svg");
    background-color:rgba(255, 255, 255, 0.3);
    opacity: 1 !important;
    &:after {
        display: none;
    }
    @media (min-width:$tv-media) {
        width: 120px;
        height: 120px;
    }
}
.swiper-button-next {
    width: $standard-padding;
    height: $standard-padding;
    position: absolute;
    z-index: 9;
    top: 48%;
    margin-top: calc(-30px + env(safe-area-inset-top));

    background-color: transparent;

    border: 0 none;
    background-position: center;
    background-repeat: no-repeat;
    right: 0;
    background-image: url("/assets/icons/Chevron_Right.svg");
    background-color:rgba(255, 255, 255, 0.3);
    opacity: 1 !important;
    &:after {
        display: none;
    }
    @media (min-width:$tv-media) {
        width: 120px;
        height: 120px;
    }
}
.swiper-slide {
    opacity: 0.3;
    transition: opacity 1s ease;
    &.swiper-slide-active {
        opacity: 1;
    }
}
.swiper-button-disabled{
    display: none;
}

button,
a,
.btn {
    cursor: pointer;
}

.mxe__car-upper-menu .swiper-slide {
    width: auto;
    margin: 0;

    .intslide {
        margin: 0 0 0 50px;

        opacity: 1;
    }
}
.mxe__car-upper-menu .swiper-slide-active .intslide {
    color: $white;

    font-size: 18px;
}

.maserati__car-configurator-wrapper {
    .monkey-way-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        video {
            transition: all 1s ease;
            width: auto !important;
            position: fixed;
            top: 0;
            left: 50%;
            /* right: 50%; */
            bottom: 0;
            /* max-width: 100vw !important; */
            transform: translate(-65%, 0);
        }
    }
    // &.fullscreen{
    //   .monkey-way-container{
    //     video{
    //       transition: all 1s ease;
    //       width: auto !important;
    //       position: fixed;
    //       top: 0;
    //       left: 50%;
    //       right: 50%;
    //       bottom: 0;
    //       /* max-width: 100vw !important; */
    //       transform: translate(-50%,0);
    //     }
    //   }
    // }
}

button.mxe__close-action,
a.mxe__close-action {
    width: 64px;
    height: 64px;
    position: fixed;
    right: 30px;
    top: 30px;
    background-image: url("assets/icons/mxe-icon_close.svg");
    background-size: contain;
    background-color: transparent;
    border: 0 none;
    z-index: 98;
}
.mxe__fixed-top-logo {
    position: fixed;
    top: 48px;
    left: 48px;
    z-index:91;
}

.mxe__model--selector-carousel {
    .swiper {
        height: 100vh;
    }
}

.mxe__model--selector-carousel .swiper-wrapper {
    height: calc(100vh - 130px);
    @media (max-width: $small-ipad-media) {
        height: calc(100vh - 145px);
    }
    .swiper-slide {
        pointer-events: none;
    }

    .mxe__bottom-car-selector-wrapper {
        overflow: hidden;
        position: absolute;
        bottom: -100vh;
        left: 0;
        right: 0;
        z-index: 98;        
        .mxe__bottom-car-selector-infos {
            @media (min-width: $tv-media) {
                padding-top:30px;
                padding-left: 120px;
                padding-right: 120px;
            }
        }
    }
    .percentage {
        max-width: 0;
        overflow: hidden;
        transition: max-width 3s ease;
    }
    .swiper-slide-active {
        pointer-events: initial;
        .percentage {
            max-width: 159px;
            @media(min-width: $tv-media) {
                max-width: 318px;
            }
        }
        .mxe__bottom-car-selector-wrapper {
            padding-bottom: calc(env(safe-area-inset-bottom));
            animation: bottomBar-animation 1s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            @media(min-width: $tv-media) {
                animation: bottomBar-animationTv 1s;
                animation-fill-mode: forwards;
                animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            }
            .mxe__bottom-car-selector-infos {
                display: flex;
                flex-direction: column;
                justify-content:end;
                @media (min-width: $tv-media) {
                    // padding-top:30px;
                    padding-left: 120px;
                    padding-right: 120px;
                }
            }
        }
    }
}
/* .monkey-way-container{
  width: 1024px;
  height: 512px;
  overflow: hidden;
  margin: 0 auto;
} */

.cloudimage-360-view-360-circle {
    bottom: 15% !important;
}


//swiper carousel on package accordion
.mxe__packages-config-panel {
    padding: 45px $standard-padding;
    @media (max-width: $small-ipad-media){
      padding: $mobile-padding;
    }
    .mxe__packages-item{
        &.not-selectable{
            .pkg-accordion-carousel{
                .swiper-wrapper{
                    opacity: 0.5;
                }
            }
        }
    }  
    .pkg-accordion {
        &.selected{
            border: 3px solid $accent;
            z-index: 2;
            @media (min-width: $tv-media) {
               border: 6px solid $accent;
            }
          }
        .pkg-accordion-heading {
            position: relative;
            .pkg-accordion-carousel
                .swiper-pagination-horizontal.swiper-pagination-bullets
                .swiper-pagination-bullet {
                margin-left: 10px;
                margin-right: 10px;
            }
            .pkg-accordion-carousel {
                position: relative;
                width: 100%;
                @media (max-width: $small-ipad-media){
                    width: 100%;
                  }
                @media (min-width:$tv-media) {
                    width: 100%;
                }
                .swiper-pagination {
                    position: absolute;
                    bottom: 12px;
                    padding: 0;
                    width: auto !important;
                    z-index: 9999;
                    max-width: 300px;
                    @media (min-width:$tv-media) {
                        bottom: 24px;
                        left: 24px;
                    }
                }

                .pkg-acc-sld-bg {
                    width: 100%;
                    height: 180px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    position: relative;
                    @media (max-width: $small-ipad-media){
                        width: 390px;
                    }
                    @media (min-width:$tv-media) {
                        width: 970px;
                        height: 380px;
                    }
                    &:after {
                        content: "";
                        background: linear-gradient(
                            90deg,
                            rgba(0, 0, 0, 0.8) 0%,
                            rgba(255, 255, 255, 0) 156%
                        );
                        background-blend-mode: multiply;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
                  
                }
                .swiper-pagination-horizontal.swiper-pagination-bullets
                    .swiper-pagination-bullet.swiper-pagination-bullet-active {
                    background: transparent;
                    color: #ffffff;
                    font-size: 20px;
                    letter-spacing: 2px;
                    border-bottom: 2px solid #ffffff;
                    width: 6px;
                    margin-right: 0;
                    @media (min-width: $tv-media) {
                        font-size: 30px;
                        width: 16px;    
                    }
                }
                .swiper-horizontal
                    > .swiper-pagination-bullets
                    .swiper-pagination-bullet
                    span,
                .swiper-pagination-horizontal.swiper-pagination-bullets
                    .swiper-pagination-bullet {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                    width: 6px;
                    margin-right: 0;
                    @media (min-width: $tv-media) {
                        font-size: 30px;
                        width: 16px;    
                    }
                }
            }
        }
    }
}
.mxe__exterior--accordion.accordion-exterior-panel.mega-accordion{
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.457143px;
    text-transform: capitalize;
    @media(min-width: $tv-media) {
        font-size: 40px;
        line-height: 48px;   
    }
}

.mxe__exterior--accordion.accordion-exterior-panel.mega-accordion.active {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.457143px;
    text-transform: capitalize;
    @media (min-width: $tv-media) {
        font-size: 40px;
        line-height: 40px;
    }
   
    .mega-accordion-heading{
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        @media (min-width: $tv-media) {
            border-bottom: 2px solid rgba(255, 255, 255, 0.5);
        }
    }
   
    .mxe__exterior--accordion:not(.active) {
       
        .mxe__exterior--accordion-heading {
             .mxe__toggle-arrow {
                //background-image: url("/assets/icons/mxe_ico-closed-accordion.svg") !important;
                transition: transform 1s cubic-bezier(0.25, 1, 0.5, 1);
                transform: rotate(0turn);
            }
        }
    }
}

//styles common for accordions
.mxe__exterior--accordion {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.457143px;
    text-transform: capitalize;
    color: $white;
    @media (min-width: $tv-media){ 
        font-size: 40px;
        line-height: 34px;
    }
    &.mega-accordion {
        font-size: 20px;
        overflow: hidden;
        @media (max-width: $small-ipad-media){
            font-size: 26px;
        }
        @media (min-width: $tv-media) {
            font-size: 40px;
        }
        .mega-config-items {
            margin-top: 1px;
            margin-bottom: 0;
        }
    }
    .mega-accordion-heading {
        border-bottom: 1px solid $white;
    }
    .mxe__toggle-arrow {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 24px;
        height: 24px;
        border: 0 none;
        display: block;
        background-color: transparent;
        background-image: url("/assets/icons/mxe_ico-closed-accordion.svg");
        background-size: auto;
        background-repeat: no-repeat;
        transition: transform 1s cubic-bezier(0.25, 1, 0.5, 1);
        transition-delay: 0.2s;
       // transform: rotate(-0.5turn);
        @media(min-width: $tv-media) {
            width: 32px;
            height: 32px;
            background-size: auto 32px;
        }
    }

    &.active {
        .mxe__toggle-arrow {
            //background-image: url("/assets/icons/mxe_ico-open-accordion.svg");
            transition: transform 1s cubic-bezier(0.25, 1, 0.5, 1);
            transform: rotate(0.5turn);
        }
    }

    &-heading {
        padding: 32px 40px;
        
        @media(min-width: $tv-media) {
            padding: 64px 80px;
        }
        cursor: pointer;
    }

    .mxe__tab-elements {
        display: block;
        min-height: 100%;
        position: relative;
        width: 100%;

        .mxe__btn-play-video {
            position: absolute;
            right: 45px;
            bottom: 0px;
            width: 24px;
            height: 24px;
            border: 0 none;
            display: block;
            background-color: transparent;
            background-image: url("/assets/icons/mxe-ico_video.svg");
            background-size: auto;
            background-repeat: no-repeat;
            @media (min-width: $tv-media) {
                width: 32px;
                height: 32px;
                background-size: 32px;
                right: 65px;
            }
        }
    }
    .opt-details {
        font-size: 14px;
        @media (max-width: $small-ipad-media){
            font-size: 13px;
        }
        @media (min-width: $tv-media){ 
            font-size: 24px;
            line-height: 34px;
        }
    }
    .opt-price {
        font-size: 14px;
        opacity: 50%;
    }
    .mxe__exterior-color-config-items {
        max-height: 0;
        overflow: hidden;
        transition: max-height 1.5s cubic-bezier(0.25, 1, 0.5, 1);
        margin-bottom: 20px;
        &.squareContainer {
            margin-right: -25px;
        }
        .mxe__int-wrapper {
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            width: 100%;
            @media(min-width:$tv-media) {
                width:1000px;
            }
            &:last-child {
                border-bottom: 0px none;
            }
        }
    }
    .mxe__exterior-color-config {
        &-items {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &.active {
        .mxe__exterior-color-config-items {
            max-height: 8000px;
            transition: max-height 20s cubic-bezier(0.25, 1, 0.5, 1);
        }
    }
}

.opt_title {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    max-width: 200px;
    color: $white;
    @media (max-width: $small-ipad-media){
        font-size: 12px;
        line-height: 14px;
    }
    @media (min-width: $tv-media) {
        font-size: 26px;
        line-height: 28px;
        max-width: 366px;
    }
}


.opt_price {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: $white;
    @media (max-width: $small-ipad-media){
        font-size: 12px;
        line-height: 14px;
    }
    @media (min-width: $tv-media) {
        font-size: 26px;
        line-height: 28px;
    }
}

.checkbox-custom {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: $white;
        border: 1px solid $primary;
    }
    :hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
        background-color: $white;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 4px;
        top: 0px;
        width: 5px;
        height: 10px;
        border: solid $primary;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

// HOTFIX AFTER REMOVE MODELS
.mxe__model--selector-container {
    &.mxe__cms-firspage {
        .swiper-pagination-fraction,
        .swiper-pagination-custom,
        .swiper-horizontal > .swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal {
            min-height: 40px;
            width:80%;
            margin-left: 9.95vw;
        }
        .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet span,
        .swiper-pagination-horizontal.swiper-pagination-bullets
            .swiper-pagination-bullet {
            flex: 0 0 auto;
        }
    }
}

body {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.cloudimage-360-inner-box {
    canvas {
        max-height: 65vh;
        width: auto !important;
        min-height: 530px;
        @media (max-width: $small-ipad-media){
            min-height: 380px;
        }
    }
}

.burger-menu {
    position: absolute;
    width: 20px;
    height: 20px;
    //right: 55px;
    //top: 86px;
    left: 25px;
    top: 22px;
    display: block;
    padding: 0px;
    z-index: 99;
    opacity: 0;

    .bar1,
    .bar2,
    .bar3 {
        width: 14px;
        height: 2px;
        left: 1130px;
        top: 76px;
        display: block;
        background-color: rgba(255, 255, 255, 0.5);
        margin-bottom: 4px;
    }
}

// HOTFIX FOR EMANUELE TEST REMOVE BEFORE GOLIVE !IMPORANTISSIMO TODO;;

@media (max-height: 420px) {
    .maserati__car-configurator-bottom-panel .maserati__car-configurator--icon {
        display: block !important;
        height: 22px !important;
    }
}

.maserati__car-configurator-video-wrapper.cast-is-active {
    .monkey-way-container video {
        height: 370px !important;
        //TODO remove this when unreal solves the problem
        left:50% !important;
        @media (max-width: $small-ipad-media){
          height: 290px !important;
        }
    }
}

.mxe__car-configurator-wrapper {
    .maserati__car-configurator-sidebar {
        .inner-header-cont {
            .sub-heading {
                &.heading-sticky {
                    transition: right 1s ease;
                }
            }
        }
    }
    &.fullview {
        .maserati__car-configurator-sidebar {
            .inner-header-cont {
                .sub-heading {
                    &.heading-sticky {
                        right: -$sidebar-width;
                        @media (min-width: $tv-media){
                            right: - calc($sidebar-tv-width - 40px);
                        }
                    }
                }
            }
        }
    }
}
.inner-header-cont {
    .sub-heading {
        &.sticky-header {
            align-content: center;
            position: fixed;
            height: 52px;
            z-index: 9999;
            background-color: black;
            width: 36%;
            display: grid;
            top: 76px;
            @media (max-width: $small-ipad-media){
                width: 33%;
            }
          }
        &.heading-sticky {
            position: fixed;
            background: $primary;
            width: calc($sidebar-width - 120px);
            top: 70px;
            right: 0;
            left: auto;
            z-index: 5;
            transition: right 300ms ease;
            @media (max-width: $small-ipad-media){
                width: calc($sidebar-mobile-width - 80px);
            }
            @media (min-width: $tv-media){
                width: calc($sidebar-tv-width - 120px);
                top: 95px;
            }
        }
    }
}

.sidebar-cc-wrapper.app-sidebar-hidden .mxe__exterior--accordion-heading.sub-heading.heading-sticky {
    right: -600px;
    @media (min-width: $tv-media){
        right: - calc($sidebar-tv-width + 120px);
    }
}

.scroll-position-element-over {
    position: relative;
    /* padding-top: 20px; */
}

.scroll-position-child-over {
    position: absolute;
    bottom: 0;
    z-index: 1;
    background: black;
    width: 100%;
    max-width: calc($sidebar-width - ($standard-padding + $standard-padding))
}
.maserati__car-configurator-video-wrapper.cast-is-active {
    .monkey-way-container video {
        height: 370px !important;
        @media (max-width: $small-ipad-media) {
            height: 290px !important;
        }
    }
}

.mxe__exterior--accordion.accordion-exterior-panel.mega-accordion:not(.active) {
    .inner-header-cont .sub-heading.heading-sticky {
        position: relative !important;
    }
}

.tv-app-and-sidebar-wrapper.fullview-mode {
    .inner-header-cont .sub-heading.heading-sticky {
        position: relative !important;
    }
}

.sidebar-cc-wrapper.app-sidebar-hidden .sub-heading.heading.sticky{ 
    width: 0 !important; 
}


mxe-car-trim{
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        width:80%;
        margin-left: 9.95vw;
    }

    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet span,
    .swiper-pagination-horizontal.swiper-pagination-bullets
        .swiper-pagination-bullet {
        flex: 0 0 auto;
    }
}

::-webkit-scrollbar {
    display:none;
}

.mxe__exterior--accordion-heading.sub-heading.secondary:not(.sticky-header){
    padding: 32px 40px 32px 60px;
    @media(min-width: $tv-media) {
        padding-left: 120px;
        padding-right: 80px;
        padding-top: 64px;
        padding-bottom: 64px;
    }
}

.selected-options-infos {
  
    font-size: 10px;
    font-family: Everett;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 16px;
    letter-spacing: 0.30px;
    word-wrap: break-word;
    text-align: left;
    color: $white;
    padding-top: 5px;
    @media(min-width: $tv-media) {
        font-size: 20px;
        line-height: 33px;
        padding-top: 10px;   
    }
}

.secondary{
    &.sticky-header{
        display:flex;
        flex-direction: column;
        padding: 32px 40px 32px 60px;
       
    }

}

.iti {
	display: block !important;
	margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-color: #c7cace;
	margin-top: -1px;
}

.iti .iti__country-list {
	box-shadow: none;
	font-size: 14px;
	margin-left: 0;
	width: 321px;
	max-height: 170px;
}

.iti__flag-container.open + input {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

// .iti .search-container input {
// 	font-size: 14px;
// 	border-color: #c7cace;
// 	border-radius: 0;
// 	padding: 5px 10px;
// }

.iti .search-container input:focus {
	outline: none;
}


ngx-intl-tel-input input {
	height: 44px;
	margin-bottom: 20px;
	padding: 10px;
	font-size: 18px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
	border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
	outline: none !important;
	border-color: #3498db;
	box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
	background-color: #e5eaf1;
}

@keyframes bottomBar-animation {
    to {
        bottom: 0;
    }
}
@keyframes bottomBar-animationTv {
    to {
        bottom: 100px;
    }
}